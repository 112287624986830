import bg from '../assets/bg.png';
import chef from '../assets/chef.png';
import G from '../assets/G.png';
import gallery01 from '../assets/gallery01.png';
import gallery02 from '../assets/gallery02.png';
import gallery03 from '../assets/gallery03.png';
import gallery04 from '../assets/gallery04.png';
import knife from '../assets/knife.png';
import logo from '../assets/logo.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.png';
import findus from '../assets/findus.png';
import laurels from '../assets/laurels.png';
import award01 from '../assets/award01.png';
import award02 from '../assets/award02.png';
import award03 from '../assets/award03.png';
import award05 from '../assets/award05.png';
import sign from '../assets/sign.png';
import quote from '../assets/quote.png';
import gericht from '../assets/gericht.png';
import logo2 from '../assets/logo2.png';
import Image from '../assets/Image.png';
import C from '../assets/C.png';
import shape3 from '../assets/shape3.png';
import aboutbanner from '../assets/aboutbanner.jpg';
import aboutimage from '../assets/aboutimage.jpg';
import badge2 from '../assets/badge2.png';
import water from '../assets/water.jpg';
import water1 from '../assets/water1.jpg';
import icon from '../assets/icon.jpg';
import removebg from '../assets/icon-removebg.png';
import Logo3 from '../assets/logo3.png';
import Logo4 from '../assets/logo4.png';
import Screen1 from '../assets/Screen1.png';
import Screen2 from '../assets/Screen2.png';
import Cafe1 from '../assets/CAFE1.jpg';
import Cafe2 from '../assets/CAFE2.jpg';
import Cafe3 from '../assets/CAFE3.jpg';
import Cafe4 from '../assets/CAFE4.jpg';
import Cafe5 from '../assets/CAFE5.jpg';
import Cafe11 from '../assets/CAFE11.jpg';
import Cafe12 from '../assets/CAFE12.jpg';
import Screen3 from '../assets/Screen3.png';
import Screen4 from '../assets/Screen4.png';
import Cafe13 from '../assets/CAFE13.jpg';
import Cafe14 from '../assets/CAFE14.jpg';
import Cafe15 from '../assets/CAFE15.jpg';
import Cafe16 from '../assets/CAFE16.jpg';
import Cafe17 from '../assets/CAFE17.jpg';
import Cafe18 from '../assets/CAFE18.jpg';
import Cafe19 from '../assets/CAFE19.jpg';
import Cafe20 from '../assets/CAFE20.jpg';
import Cafe21 from '../assets/CAFE21.jpg';
import Menu1 from '../assets/Menu1.png';
import Menu2 from '../assets/Menu2.png';
import Menu3 from '../assets/Menu3.png';
import Menu4 from '../assets/Menu4.png';
import Drink from '../assets/Drink1.png';
import Drink2 from '../assets/Drink2.png';
import Drink11 from '../assets/Drink11.png';
import Drink12 from '../assets/Drink12.png';
import Screen11 from '../assets/Screen11.png';
import Screen12 from '../assets/Screen12.png';
import Screen13 from '../assets/Screen13.png';
import Screen14 from '../assets/Screen14.png';
import Menu11 from '../assets/Menu11.png';
import Menu12 from '../assets/Menu12.png';
import Menu13 from '../assets/Menu13.png';
import Menu14 from '../assets/Menu14.png';


export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
  logo2,
  Image,
  C,
  shape3,
  aboutbanner,
  aboutimage,
  badge2,
  water,
  water1,
  icon,
  removebg,
  Logo3,
  Logo4,
  Screen1,
  Screen2,
  Cafe1,
  Cafe2,
  Cafe3,
  Cafe4,
  Cafe5,
  Cafe11,
  Cafe12,
  Screen3,
  Screen4,
  Cafe13,
  Cafe14,
  Cafe15,
  Cafe16,
  Cafe17,
  Cafe18,
  Cafe19,
  Cafe20,
  Cafe21,
  Menu1,
  Menu2,
  Menu3,
  Menu4,
  Drink,
  Drink2,
  Screen11,
  Screen12,
  Menu11,
  Menu12,
  Menu13,
  Menu14,
  Screen13,
  Screen14,
  Drink11,
  Drink12,
};
