// import React from 'react';
// import { Navbar } from '../components';
// import './Contact.css';
// import { FiFacebook, FiTwitter, FiInstagram  , FiMap} from 'react-icons/fi';
// import { Footer } from '../container';
// import '../container/Footer/Footer.css';



// const Contact = () => {
//   // const map = 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d904726.6131739549!2d85.24565535!3d27.65273865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2snp!4v1652535615693!5m2!1sen!2snp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ';
  
//   return (
//     <>
//       <Navbar />
      
//     </>
//   );
// }

// export default Contact;


import React from 'react';
import ContactSection from '../components/ContactSection';
import Map from '../components/Map';
import { Navbar } from '../components';
import { Footer } from '../container';

export default function Contact() {
  return (
    <>
      <Navbar></Navbar>
      <ContactSection />
      <Map />
      <Footer />
    </>
  );
}
