import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => (
  <div className="app__aboutus app__bg flex__center section__padding" id="about">
    <div className="app__aboutus-overlay flex__center">
      <img src={images.C} alt="G_overlay" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Book Your Reservation</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Elevate your experience at Cafe Cassia by securing your spot in our captivating haven of elegance.
          Whether you're planning an intimate gathering with friends or simply seeking a moment of solitude, 
          our book your reservation service ensures your visit is seamless and tailored to your preferences.
        </p>
        <button type="button" className="custom__button1">
          <a 
            href="https://wa.me/+254703590989?text=Hi,%20I%20would%20like%20to%20book%20a%20table." 
            target="_blank" 
            rel="noopener noreferrer"
          >
            BOOK NOW
          </a>
        </button>
      </div>

      {/* If you plan to enable the History section later */}
      {/* <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">Adipiscing tempus ullamcorper lobortis odio tellus arcu volutpat. Risus placerat morbi volutpat habitasse interdum mi aliquam In sed odio nec aliquet.</p>
        <button type="button" className="custom__button1"><a href="#home">Know More</a></button>
      </div> */}
    </div>
  </div>
);

export default AboutUs;
