// import React from 'react';

// import { SubHeading, MenuItem } from '../../components';
// import { data, images } from '../../constants';
// import './SpecialMenu.css';

// const SpecialMenu = () => (
//   <div className="app__specialMenu flex__center section__padding" id="menu">
//     <div className="app__specialMenu-title">
//       <SubHeading title="Menu that fits your palatte" />
//       <h1 className="headtext__cormorant1">Today&apos;s Special</h1>
//     </div>

//     <div className="app__specialMenu-menu">
//       <div className="app__specialMenu-menu_wine  flex__center">
//         <p className="app__specialMenu-menu_heading">MAIN</p>
//         <div className="app__specialMenu_menu_items">
//           {data.wines.map((wine, index) => (
//             <MenuItem key={wine.title + index} title={wine.title} price={wine.price} tags={wine.tags} />
//           ))}
//         </div>
//       </div>

//       <div className="app__specialMenu-menu_img">
//         <img src={images.menu} alt="menu__img" />
//       </div>

//       <div className="app__specialMenu-menu_cocktails  flex__center">
//         <p className="app__specialMenu-menu_heading">DRINKS</p>
//         <div className="app__specialMenu_menu_items">
//           {data.cocktails.map((cocktail, index) => (
//             <MenuItem key={cocktail.title + index} title={cocktail.title} price={cocktail.price} tags={cocktail.tags} />
//           ))}
//         </div>
//       </div>
//     </div>

//     <div style={{ marginTop: 15 }}>
//       <button type="button" className="custom__button">View All</button>
//     </div>
//   </div>
// );

// export default SpecialMenu;
import React from 'react';
import { SubHeading } from '../../components';
import { images } from '../../constants';
import './SpecialMenu.css';

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="OUR MENU" />
    </div>

    {/* Display the images */}
    <div className="app__specialMenu-images">
      <img src={images.Screen12} alt="Menu Page 1" className="specialMenu__image" />
      <img src={images.Screen11} alt="Menu Page 2" className="specialMenu__image" />
    </div>

    {/* Images are split for smaller screens */}
    <div className="app__specialMenu-images-split">
      <img src={images.Menu11} alt="Menu Part 1" className="specialMenu__image-split" />
      <img src={images.Menu12} alt="Menu Part 2" className="specialMenu__image-split" />
      <img src={images.Menu13} alt="Menu Part 3" className="specialMenu__image-split" />
      {/* <img src={images.Menu4} alt="Menu Part 1" className="specialMenu__image-split" /> */}
      {/* <img src={images.Screen2Part2} alt="Menu Part 2" className="specialMenu__image-split" />
      <img src={images.Screen2Part3} alt="Menu Part 3" className="specialMenu__image-split" /> */}
    </div>

    <div style={{ marginTop: 15 }}>
      <a href="Menu"><button type="button" className="custom__button">View All</button></a>
    </div>
  </div>
);

export default SpecialMenu;
