import React from 'react';
// import  {createBrowserRouter, RouterProvider, Route, Routes} from  'react-router-dom';

import { AboutUs, Chef, FindUs, Footer, Gallery, Header, Intro, Laurels, SpecialMenu } from './container';
import { Navbar } from './components';
import './App.css';
// import {About, Contact, Home } from './pages';
// import About from './pages/About';
// import Home from './pages/Home';
// import Contact from './pages/Contact';

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    <SpecialMenu />
    {/* <Chef /> */}
    {/* <Intro /> */}
    {/* <Laurels /> */}
    <Gallery />
    <FindUs />
    <Footer />
  </div>
  
);

export default App;


// export default function App() {
//   return (
//       <div>
//         <BrowserRouter>
//           <Routes>
//             <Route index element= {< About />} />
//             <Route path ="/about" element={ < About />} />
//           </Routes>
//         </BrowserRouter>
//       </div>
//   )
// }

// const App = () => (
//     <div>
//         <BrowserRouter>
//            <Routes>
// //             <Route index element= {< Home />} />
// //           </Routes>
// //         </BrowserRouter>
// //     </div>
  
// );

// export default App;