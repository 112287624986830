// import React from 'react';

// import { SubHeading } from '../../components';
// import { images } from '../../constants';
// import './Header.css';

// const Header = () => (
//   <div className="app__header app__wrapper section__padding" id="home">
//     <div className="app__wrapper_info">
//       <SubHeading title="Chase the new flavour" />
//       <h1 className="app__header-h1">The Key To Fine Dining</h1>
//       <p className="p__opensans1" style={{ margin: '2rem 0' }}>Sit tellus lobortis sed senectus vivamus molestie. Condimentum volutpat morbi facilisis quam scelerisque sapien. Et, penatibus aliquam amet tellus </p>
//       <button type="button" className="custom__button1"><a href="#menu">Explore Menu</a></button>
//     </div>

//     <div className="app__wrapper_img">
//       <img src={images.welcome} alt="header_img" />
//     </div>
//   </div>
// );

// export default Header;
import React from 'react';
import { open } from '../../constants'
import './Header.css';
// import { SubHeading } from '../../components';
import { images } from '../../constants';

const Header = () => {
  return (
    <div className="app__video">
      <video
        src={open}
        type="video/mp4"
        loop
        autoPlay
        muted
      />
      <div className="app__video-overlay">
        <div className="app__header app__wrapper section__padding" id="home">
          <div className="app__wrapper_info1">
            <h1 className='p__cormorant1'>Chase The New Flavour</h1>
            <img src={images.spoon} alt="spoon_image" className="spoon__img" />
            <h1 className="app__header-h1">The Key To Fine Dining</h1>
            <p className="p__opensans1" style={{ margin: '2rem 0' }}>a voyage into the heart of fine dining where the freshest ingredients meet the artistry of culinary excellence. Nestled in the heart of Nairobi. <br /> Let your taste buds explore the essence of gourmet cuisine.</p>
            <button type="button" className="custom__button1"><a href="#menu">Explore Menu</a></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;