import React from 'react';
import styled from 'styled-components';
// import MapImg from '../assets/images/map.png';
import PText from './PText';

const MapStyles = styled.div`

  .location {
    width: 80%;
    margin: auto;
    padding: 0px 0px;
  }
  .location iframe {
    width: 100%;
  }
  .heading {
    text-align: left;
    color: #060606;
    font-size: 3rem;
    padding-bottom: 1rem;
    padding-top: 0rem;
  }
  .heading span {
    color: #c2a918d7;
    background: var(--light-red);
    border-radius: 0.5rem;
    padding: 0.2rem 1.5rem;
  }
`;

export default function Map() {
  return (
    <MapStyles>
      {/* <img src={MapImg} alt="Map" /> */}
      <div className="location">
        <h1 className="heading">OUR LOCATION</h1>
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7285369847496!2d36.73403567454212!3d-1.3391864356853247!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1b002b951535%3A0x94a2ec1b2fc85121!2sCafe%20Cassia!5e0!3m2!1sen!2ske!4v1726269085505!5m2!1sen!2ske" 
        width="600" 
        height="450" 
        style={{ border: '0' }} 
        allowFullScreen 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
      </div>
    </MapStyles>
  );
}
