import React, { useState, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';
import images from '../../constants/images';
import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Scroll event to toggle the background color of the navbar
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <nav className={`app__navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="app__navbar-left">
        <ul className="app__navbar-links">
          <li className="p__opensans"><a href="Home">HOME</a></li>
          <li className="p__opensans"><a href="About">ABOUT</a></li>
          <li className="p__opensans"><a href="Menu">MENU</a></li>
        </ul>
      </div>
      <div className="app__navbar-logo">
        <a href="Home"><img src={images.Image} alt="app__logo" /></a>
      </div>
      <div className="app__navbar-right">
        <ul className="app__navbar-links">
          <li className="p__opensans"><a href="Gallery">GALLERY</a></li>
          <li className="p__opensans"><a href="Contact">CONTACT</a></li>
        </ul>
        <div className="app__navbar-login">
          <div />
          <a 
            href="https://wa.me/+254703590989?text=Hi,%20I%20would%20like%20to%20book%20a%20table." 
            className="p__opensans" 
            target="Hi," 
            rel="noopener noreferrer"
          >
            BOOK TABLE
          </a>
        </div>
      </div>
      <div className="app__navbar-smallscreen">
  <GiHamburgerMenu color="#000" fontSize={27} onClick={() => setToggleMenu(true)} />
  {toggleMenu && (
    <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
      <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)} />
      <ul className="app__navbar-smallscreen_links">
        <li><a href="Home" onClick={() => setToggleMenu(false)}>HOME</a></li>
        <li><a href="About" onClick={() => setToggleMenu(false)}>ABOUT</a></li>
        <li><a href="Menu" onClick={() => setToggleMenu(false)}>MENU</a></li>
        <li><a href="Gallery" onClick={() => setToggleMenu(false)}>GALLERY</a></li>
        <li><a href="Contact" onClick={() => setToggleMenu(false)}>CONTACT</a></li>
        <li>
          <a 
            href="https://wa.me/+254703590989?text=Hi,%20I%20would%20like%20to%20book%20a%20table." 
            onClick={() => setToggleMenu(false)}
            className="booktable__button"
            target="_blank" 
            rel="noopener noreferrer"
          >
            BOOK TABLE
          </a>
        </li>
      </ul>
    </div>
  )}
</div>

    </nav>
  );
};

export default Navbar;
