import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './index.css';
import App from "./App";
import About from "./pages/About";
import Menu from "./pages/Menu";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";

ReactDOM.render(
  <Router>
    <Routes>
      {/* This will render App at the root path (localhost:3000) */}
      <Route path="/" element={<App />} /> 
      
      {/* Other routes */}
      <Route path="/about" element={<About />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/contact" element={<Contact />} />

      {/* Optional: Handle undefined routes */}
      <Route path="*" element={<App />} /> {/* Redirect to home if route is not found */}
    </Routes>
  </Router>,
  document.getElementById('root')
);
