import React, { useEffect } from 'react';
import './Gallery.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Navbar } from '../components';
import { images } from '../constants';
import { Footer } from '../container';
// Importing personal images


const Gallery = () => {
    useEffect(() => {
        const overlay = document.getElementById('overlay');
        const image = overlay.querySelector('img');
        const prevButton = document.getElementById('prevButton');
        const nextButton = document.getElementById('nextButton');
        const exitButton = document.getElementById('exitButton');

        // Hide overlay by default
        overlay.style.display = 'none';

        const showOverlay = (imgSrc) => {
            image.src = imgSrc;
            overlay.style.display = 'flex';
            prevButton.style.display = 'block';
            nextButton.style.display = 'block';
            exitButton.style.display = 'block';
        };

        const hideOverlay = () => {
            overlay.style.display = 'none';
        };

        const handleNext = (event) => {
            event.stopPropagation();
            const currentImgSrc = image.src;
            const currentImg = document.querySelector(`#image-gallery img[src="${currentImgSrc}"]`);
            const nextImg = currentImg.closest('.image').nextElementSibling?.querySelector('img') ||
                document.querySelector('#image-gallery img');
            showOverlay(nextImg.src);
        };

        const handlePrev = (event) => {
            event.stopPropagation();
            const currentImgSrc = image.src;
            const currentImg = document.querySelector(`#image-gallery img[src="${currentImgSrc}"]`);
            const prevImg = currentImg.closest('.image').previousElementSibling?.querySelector('img') ||
                document.querySelector('#image-gallery img:last-child');
            showOverlay(prevImg.src);
        };

        const handleExit = (event) => {
            event.stopPropagation();
            hideOverlay();
        };

        document.querySelectorAll('.img-overlay').forEach(overlay => {
            overlay.addEventListener('click', (event) => {
                event.preventDefault();
                const imgSrc = overlay.previousElementSibling.getAttribute('src');
                showOverlay(imgSrc);
            });
        });

        overlay.addEventListener('click', hideOverlay);
        nextButton.addEventListener('click', handleNext);
        prevButton.addEventListener('click', handlePrev);
        exitButton.addEventListener('click', handleExit);

        return () => {
            overlay.removeEventListener('click', hideOverlay);
            nextButton.removeEventListener('click', handleNext);
            prevButton.removeEventListener('click', handlePrev);
            exitButton.removeEventListener('click', handleExit);
        };
    }, []);

    return (
        <div>
            <Navbar />
            <section id="gallery">
                <div className="container">
                    <div id="image-gallery">
                        <div className="row">
                            {[images.Cafe13, images.Cafe14, images.Cafe15, images.Cafe16, images.Cafe17, images.Cafe18, images.Cafe19, images.Cafe20, images.Cafe21,images.Cafe5,images.Cafe4,images.Cafe3,].map((imgSrc, index) => (
                                <div key={index} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 image">
                                    <div className="img-wrapper">
                                        <img src={imgSrc} className="img-responsive" alt={`Gallery Image ${index + 1}`} />
                                        <div className="img-overlay">
                                            <i className="fas fa-plus-circle" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div id="overlay">
                    <img alt="Overlay" />
                    <div id="prevButton" style={{ display: 'none' }}><i className="fas fa-chevron-left"></i></div>
                    <div id="nextButton" style={{ display: 'none' }}><i className="fas fa-chevron-right"></i></div>
                    <div id="exitButton" style={{ display: 'none' }}><i className="fas fa-times"></i></div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Gallery;
